import { Module, MutationTree } from "vuex";
import { NotificationStateInterface } from "../models/NotificationStateInterface";
import Notification from "../models/Notification";
import { RootStateInterface } from "@/store/rootState";

export const state: NotificationStateInterface = {
    refreshNotifications: false,
    notifications: [],
};

export const mutations: MutationTree<NotificationStateInterface> = {
    setNotifications(st, payload: Notification[]) {
        st.notifications = payload;
    },
    setRefreshNotifications(st, payload: boolean) {
        st.refreshNotifications = payload;
    }
};

const namespaced: boolean = true;

export const notifications: Module<NotificationStateInterface, RootStateInterface> = {
    namespaced,
    state,
    mutations,
};
