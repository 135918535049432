import Vue from 'vue';
import './bootstrap/index';
import store from './store/index';
import router from './router/index';
import i18n from './i18n/index';
import 'theme/design.scss';
import 'theme/base.scss';
import AppLayout from 'modules/core/components/AppLayout.vue';
import { WebSocketClient } from 'modules/websockets/models/WebSocketClient';
Vue.config.productionTip = false;

const wsClient = new WebSocketClient();
wsClient.initConnection();

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(AppLayout),
}).$mount('#app');
