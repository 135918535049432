import VueI18n from 'vue-i18n';
import en from './en';
import pl from './pl';

const messages = {
  en,
  pl,
};

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  pluralizationRules: {
    pl: (givenNumber) => {
      if (givenNumber === 0) {
        return 0;
      } else if (givenNumber === 1) {
        return 1;
      } else if ([2, 3, 4].includes(givenNumber % 10)) {
        const lastTwo = givenNumber.toString().slice(-2);
        if (lastTwo[0] === '1') {
          return 0;
        } else {
          return 2;
        }
      } else {
        return 0;
      }
    },
  },
});

export default i18n;
