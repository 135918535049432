import { CoreStateInterface } from './../interfaces/coreState';
import { Module, MutationTree } from 'vuex';
import { RootStateInterface } from '@/store/rootState';

export const state: CoreStateInterface = {
  hideSidebar: false,
  currentActingBrokerId: null,
  companyLogoBlob: null,
  refreshNotifications: false,
};

export const mutations: MutationTree<CoreStateInterface> = {
  toggleSidebar(st, payload) {
    // tslint:disable-line
    st.hideSidebar = !st.hideSidebar;
  },
  changeSidebar(s, payload) {
    s.hideSidebar = payload;
  },

  setCurrentActingBrokerId(st, payload) {
    st.currentActingBrokerId = payload?.currentActingBrokerId ?? null;
  },

  setCompanyLogoBlob(st, payload: string | null) {
    st.companyLogoBlob = payload;
  },

  setRefreshNotifications(st, payload: boolean) {
    st.refreshNotifications = payload;
  }
};

const namespaced: boolean = true;

export const core: Module<CoreStateInterface, RootStateInterface> = {
  namespaced,
  state,
  mutations,
};
