import { WebSocketAction } from '../enums/WebSocketAction';

export class WebSocketRequest {
    public action: WebSocketAction;
    public payload?: any;

    public constructor(action: WebSocketAction, payload?: any) {
      this.action = action;
      this.payload = payload;
    }
}
