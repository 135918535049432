export enum WebSocketAction {
  CORE_HEALTH_CHECK = 'core.healthcheck',
  CORE_CONNECTION_CLOSED = 'core.connectionClosed',
  CORE_INIT = 'core.init',
  CHAT_SEND_MESSAGE = 'chat.sendMessage',
  CHAT_READ_MESSAGE = 'chat.readMessage',
  CHAT_MARK_AS_READ = 'chat.markAsRead',
  CHAT_INIT = 'chat.init',
  NOTIFICATION_SEND = 'notification.send',
  MORTGAGE_UPDATE_STATUS = 'mortgage.updateStatus',
  USER_LOGOUT = 'user.logout',
  USER_LOGIN = 'user.login',
  USER_IMPERSONATE = 'user.impersonate',
}
