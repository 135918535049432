import plLocale from 'element-ui/lib/locale/lang/pl';

export default {
  test: 'Test PL',
  search: 'Szukaj klienta...',
  backend: {
    general: {
      userNotExist: 'Podany użytkownik nie istnieje w systemie',
      wrongUserPass: 'Niepoprawna nazwa użytkownika lub hasło',
    },
  },
  general: {
    save: 'Zapisz',
    accountNotConfirmed: `Twoje konto nie zostało potwierdzone - kliknij w link wysłany do Ciebie w wiadomości e-mail,
      aby potwierdzić konto. W przeciwnym razie, po 30 dniach konto zostanie zablokowane`,
    goToSettings: 'Przejdź do ustawień',
    sendMailAgain: 'Wyślij e-mail ponownie',
    mailSent: 'Wiadomość została wysłana - sprawdź swoją skrzynkę',
    confirmYourAccount: 'Potwierdź swoje konto',
    personalDataNotFilled: `Prosimy wypełnić dane osobowe powiązane z kontem -
      są one potrzebne do utworzenia poprawnego rachunku/faktury za usługę`,
    fillPersonalData: 'Wypełnij swoje dane osobowe',
    clickToCopy: 'Kliknij, aby skopiować wartość',
    delete: 'Usuń',
    edit: 'Edytuj',
    back: 'Powrót',
    refreshData: 'Odśwież dane',
    authorizationError: 'Brak uprawnień do wykonania danej czynności!',
    massActions: 'Akcje',
    warning: 'Uwaga!',
    unassign: 'Wypisz się',
    cancel: 'Anuluj',
    newClient: 'Dodaj klienta',
    of: 'pl',
  },
  menu: {
    dashboard: 'Dashboard',
    profile: 'Profil użytkownika',
    settings: 'Ustawienia',
    logout: 'Wyloguj',
    impersonate: 'Wciel się',
    workers: 'Pracownicy',
    projects: 'Projekty',
    workspaces: 'Obszary robocze',
    reports: 'Raporty',
    tags: 'Tagi',
    timesheet: 'Aktywności',
    invitations: 'Zaproszenia',
    clients: 'Klienci',
    messages: 'Wiadomości',
    credits: 'Kredyty',
    brokers: 'Pośrednicy',
    allClients: 'Wszyscy klienci',
    applicationsInProgress: 'Wnioski w toku',
    activeMortgages: 'Aktywne hipoteki',
    mortgages: 'Hipoteki',
    allMortgages: 'Wszystkie hipoteki',
    company: 'Firma',
  },
  forms: {
    required: 'To pole jest wymagane',
    earlierDate: 'Proszę wybrać wcześniejszą date',
    showButton: 'Pokaż',
    detailsButton: 'Szczegóły',
    editButton: 'Edytuj',
    options: {
      langs: {
        AF: 'Afganistan',
        AX: 'Wyspy Ålanda',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andora',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua i Barbuda',
        AR: 'Argentyna',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbejdżan',
        BS: 'Wyspy Bahama',
        BH: 'Bahrain',
        BD: 'Bangladesz',
        BB: 'Barbados',
        BY: 'Białoruś',
        BE: 'Belgia',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Boliwia',
        BA: 'Bośnia and Hercegowina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazylia',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bułgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Kambodża',
        CM: 'Kamerun',
        CA: 'Kanada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Czad',
        CL: 'Chile',
        CN: 'Chiny',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Kolumbia',
        KM: 'Comoros',
        CG: 'Kongo',
        CD: 'Demkoratyczna Republika Konga',
        CK: 'Cook Islands',
        CR: 'Kostaryka',
        CI: 'Cote D\'Ivoire',
        HR: 'Chorwacja',
        CU: 'Kuba',
        CY: 'Cypr',
        CZ: 'Czechy',
        DK: 'Dania',
        DJ: 'Djibouti',
        DM: 'Dominika',
        DO: 'Dominikana',
        EC: 'Ekwador',
        EG: 'Egipt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Etiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fidżi',
        FI: 'Finlandia',
        FR: 'Francja',
        GF: 'Gujana Francuska',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Gruzja',
        DE: 'Niemcy',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Grecja',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Gwatemala',
        GG: 'Guernsey',
        GN: 'Gwinea',
        GW: 'Guinea-Bissau',
        GY: 'Gujana',
        HT: 'Haiti',
        HM: 'Heard Island and Mcdonald Islands',
        VA: 'Holy See (Vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Węgry',
        IS: 'Islandia',
        IN: 'Indie',
        ID: 'Indonezja',
        IR: 'Iran, Islamic Republic Of',
        IQ: 'Irak',
        IE: 'Irlandia',
        IM: 'Isle of Man',
        IL: 'Izrael',
        IT: 'Włochy',
        JM: 'Jamajka',
        JP: 'Japonia',
        JE: 'Jersey',
        JO: 'Jordania',
        KZ: 'Kazachstan',
        KE: 'Kenia',
        KI: 'Kiribati',
        KP: 'Demokratyczna Republika Korei',
        KR: 'Republika Korei',
        XK: 'Kosowo',
        KW: 'Kuwejt',
        KG: 'Kirgistan',
        LA: 'Lao People\'s Democratic Republic',
        LV: 'Łotwa',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libyan Arab Jamahiriya',
        LI: 'Lichtenstein',
        LT: 'Litwa',
        LU: 'Luksemburg',
        MO: 'Macao',
        MK: 'Macedonia, The Former Yugoslav Republic of',
        MG: 'Madagaskar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Meksyk',
        FM: 'Micronesia, Federated States of',
        MD: 'Moldova, Republic of',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Holandia',
        AN: 'Netherlands Antilles',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norwegia',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territory, Occupied',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Filipiny',
        PN: 'Pitcairn',
        PL: 'Polska',
        PT: 'Portugalia',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Reunion',
        RO: 'Rumunia',
        RU: 'Federacja Rosyjska',
        RW: 'Rwanda',
        SH: 'Saint Helena',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Słowacja',
        SI: 'Słowenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'Południowa Afryka',
        GS: 'South Georgia and the South Sandwich Islands',
        ES: 'Hiszpania',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Szwecja',
        CH: 'Szwajcaria',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunezja',
        TR: 'Turcja',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraina',
        AE: 'United Arab Emirates',
        GB: 'Wielka Brytania',
        US: 'Stany Zjednoczone (USA)',
        UM: 'United States Minor Outlying Islands',
        UY: 'Urugwaj',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Wenezuela',
        VN: 'Wietnam',
        VG: 'Virgin Islands, British',
        VI: 'Virgin Islands, U.S.',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
      },
      currencies: {
        PLN: 'Polski złoty (PLN)',
        EUR: 'Euro (EUR)',
        USD: 'Dolar amerykański (USD)',
        GBP: 'Funt brytyjski (GBP)',
      },
      currenciesSuffix: {
        PLN: 'zł',
        EUR: '€',
        USD: '$',
        GBP: '£',
      },
    },
    error: 'Błąd: {msg}',
    invalidEmail: 'Wprowadź adres e-mail w prawidłowym formacie',
    optional: '(opcjonalny)',
    passwordsNotMatch: 'Podane hasła nie są takie same',
  },
  impersonate: {
    general: 'Wciel się',
    email: 'E-mail',
    impersonate: 'Wciel się',
    success: 'Sukces. Jesteś zalogowany jako: ',
  },
  login: {
    title: 'Zaloguj się do Timemize',
    email: 'Adres e-mail',
    password: 'Hasło',
    login: 'Zaloguj',
    forgotPassword: 'Zapomniałem hasła',
    dontHaveAccount: 'Nie masz konta?',
  },
  logout: {
    success: 'Użytkownik wylogowany pomyślnie',
  },
  languagePicker: {
    pl: 'Polski',
    en: 'English',
  },
  profileDropdown: {
    myProfile: 'Mój profil',
    logout: 'Wyloguj',
    licenseDaysLeft: '{daysLeft} dni do końca licencji',
  },
  workspaceDropdown: {
    successChange: 'Zmieniono Workspace!',
    errorChange: 'Błąd zmiany Workspacea!',
  },
  typePicker: {
    line: 'Wykres liniowy',
    pie: 'Wykres kołowy',
    bar: 'Wykres słupkowy',
    table: 'Tabela danych',
  },
  dataTable: {
    delete: 'Usuń',
  },
  datePicker: {
    startDate: 'Data początkowa',
    endDate: 'Data końcowa',
    lastWeek: '7 dni',
    lastTwoWeeks: '14 dni',
    lastSmallMonth: '28 dni',
    lastMonth: '30 dni',
    lastThreeMonths: '3 miesiące',
    lastSixMonths: '6 miesięcy',
    lastYear: 'rok',
  },
  chart: {
    noData: 'Brak danych',
    refresh: 'Odśwież wykres',
    navigateUp: 'Przejdź poziom wyżej',
  },
  profile: {
    title: 'Profil użytkownika',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    email: 'Email',
    name: 'Nazwa firmy',
    vatnr: 'NIP',
    phone: 'Numer telefonu',
    address: 'Adres',
    street: 'Ulica',
    streetNumber: 'Nr budynku',
    houseNumber: 'Nr mieszkania',
    companyName: 'Firma',
    postcode: 'Kod pocztowy',
    city: 'Miasto',
    country: 'Kraj',
    success: 'Dane zaktualizowano pomyślnie',
  },
  settings: {
    title: 'Ustawienia',
    changePasswordTitle: 'Zmiana hasła',
    changePassword: 'Zmień hasło',
    oldPassword: 'Aktualne hasło',
    password: 'Nowe hasło',
    repeatPassword: 'Powtórz hasło',
    changePasswordSuccess: 'Hasło zmienione',
    general: 'Ogólne',
    currency: 'Bazowa waluta',
    changeSuccess: 'Dane zmienione pomyślnie',
  },
  notification: {
    header: 'Wyślij powiadomienie',
    title: 'Tytuł',
    body: 'Wiadomość',
    send: 'Wyślij',
  },
  resetPassword: {
    title: 'Proszę wprowadzić swój adres e-mail, na który wysłany zostanie link do resetu hasła',
    backToLogin: 'Powrót do logowania',
    success: 'Na podany adres e-mail został wysłany link do resetu hasła',
    label: 'Wyślij',
    email: 'Adres e-mail',
    failure: 'Błąd podczas procesu resetu hasła, spróbuj ponownie',
    failureMsg: 'Błąd podczas procesu resetu hasła: {msg}',
  },
  setNewPassword: {
    title: 'Wprowadź nowe hasło',
    backToLogin: 'Powrót do logowania',
    success: 'Hasło zostało zmienione pomyślnie',
    label: 'Zmień hasło',
    password: 'Nowe hasło',
    repeatPassword: 'Powtórz nowe hasło',
    failure: 'Błąd podczas ustawiania nowego hasła, spróbuj ponownie',
    failureMsg: 'Błąd podczas ustawiania nowego hasła: {msg}',
  },
  dashboard: {
    title: 'Dashboard',
    paymentMethods: 'Wykorzystywane metody płatności',
    shippingMethods: 'Wykorzystywane metody dostawy',
    sessions: 'Sesje',
    users: 'Użytkownicy',
    transactionsCount: 'Liczba transakcji',
    failedTransactionCount: 'Nieudane',
    successTransactionCount: 'Udane',
    transactionsIncome: 'Przychód z transakcji',
    transactionsLost: 'Zysk utracony z transakcji',
    averageCartValue: 'Średnia wartość koszyka',
    campaignIncome: 'Przychód ze źródeł',
    recentClients: 'Ostatni Klienci',
    chart: {
      total: 'Wszystkie',
    },
    mortgageRenewals: 'Odnowienia hipotek',
  },
  addEntry: {
    cardTitle: 'Dodaj aktywność',
    title: 'Tytuł',
    addBtn: 'Dodaj aktywność',
    project: 'Projekt',
    mode: 'Tryb',
    timeFrom: 'Czas od',
    timeTo: 'Czas do',
    duration: 'Czas trwania',
    cost: 'Koszt',
    description: 'Opis',
    selectDateTime: 'Wybierz datę i czas',
  },
  projects: {
    title: 'Projekty',
    error: {
      unassignErrorUserIsOwnerOfProject: 'Nie możesz wypisać się z projektu {name} ponieważ jesteś jego właścicielem',
    },
    unassignSuccessFromProjects: 'Zostałeś pomyślnie wypisany z {n} projektów | Zostałeś pomyślnie wypisany z projektu | Zostałeś pomyślnie wypisany z {n} projektów',
    unassignFromProjectsWarning: 'Czy chcesz wypisać się z wybranych projektów? Tej akcji nie można cofnąć!',
    unassignFromProjectWarning: 'Czy chcesz wypisać się z tego projektu? Tej akcji nie można cofnąć!',
  },
  workers: {
    title: 'Pracownicy',
    addWorker: 'Dodaj pracownika',
    email: 'E-mail',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    addWorkerPage: {
      title: 'Dodawanie pracownika',
      createAccount: 'Utwórz nowego użytkownika',
      email: 'E-mail',
      emailText: 'Wprowadź e-mail użytkownika, którego chcesz dodać do Workspace',
      submitButton: 'Dodaj pracownika',
      workerAdded: 'Pracownik został dodany!',
      workerRemoved: 'Pracownik został usunięty!',
      role: 'Rola',
      options: {
        role: {
          ADMIN_WORKSPACE: 'Administrator',
          OWNER_WORKSPACE: 'Własciciel',
          WORKER_WORKSPACE: 'Pracownik',
        },
      },
    },
    showWorkerPage: {
      title: 'Szczegóły pracownika',
      returnButton: 'Powrót do listy pracowników',
    },
    removeWorkerPopup: {
      title: 'Uwaga! Usuwasz pracownika!',
      info: 'Czy na pewno chcesz usunąć pracownika ',
      confirmButton: 'Tak',
      cancelButton: 'Nie',
    },
  },
  workspaces: {
    title: 'Obszary robocze',
    add: 'Dodaj obszar roboczy',
    addForm: {
      title: 'Nowy obszar roboczy',
      name: 'Nazwa',
      domain: 'Domena',
      add: 'Dodaj',
      success: 'Pomyślnie dodano obszar roboczy',
      generalError: 'Nie udało się dodać obszaru roboczego. Spróbuj ponownie.',
    },
    error: {
      unassignErrorUserIsOwnerOfWorkspace: 'Nie możesz wypisać się z obszaru roboczego {name} ponieważ jesteś jego właścicielem',
      unassignErrorUserIsOwnerOfProject: 'Nie możesz wypisać się z obszaru roboczego {name} ponieważ posiada on projekt, którego jesteś właścicielem',
      unassignErrorUserHasProjectInWorkspace: 'Nie możesz wypisać sie z obszaru roboczego {name} ponieważ jesteś członkiem w projekcie w danym obszarsze. Wypisz się najpierw z projektów w obszarze roboczym {name}',
    },
    unassignSuccessFromWorkspaces: 'Zostałeś pomyślnie wypisany z {n} obszarów roboczych | Zostałeś pomyślnie wypisany z obszaru roboczego | Zostałeś pomyślnie wypisany z {n} obszarów roboczych',
    unassignFromWorkspacesWarning: 'Czy chcesz wypisać się z wybranych obszarów roboczych? Tej akcji nie można cofnąć!',
    unassignFromWorkspaceWarning: 'Czy chcesz wypisać się z wybranego obszaru roboczego? Tej akcji nie można cofnąć!',
  },
  invitations: {
    sentInvitations: 'Wysłane zaproszenia',
    recivedInvitations: 'Otrzymane zaproszenia',
    returnButton: 'Powrót do listy zaproszeń',
    table: {
      userName: 'Zaproszenie do',
      adminName: 'Zaproszenie od',
      resourceName: 'Nazwa',
      resourceType: 'Zasób',
      resourceTypeList: {
        workspace: 'Obszar roboczy',
        project: 'Projekt',
      },
      role: 'Rola',
      roleList : {
        owner_project: 'Właściciel projektu',
        admin_project: 'Administrator projektu',
        worker_project: 'Pracownik projektu',
        owner_workspace: 'Właściciel obszaru roboczego',
        admin_workspace: 'Administrator obszaru roboczego',
        worker_workspace: 'Pracownik obszaru roboczego',
      },
      resourceList: {
        workspace: 'Obszar roboczy',
        project: 'Projekt',
      },
      statusList: {
        active: 'Aktywny',
        accepted: 'Zaakceptowany',
        rejected: 'Odrzucony',
        expired: 'Wygasły',
      },
      receivedTime: 'Data otrzymania',
      expireTime: 'Data wygaśnięcia',
      status: 'Status',
      acceptButton: 'Dołącz',
      rejectButton: 'Odrzuć',
      deleteButton: 'Usuń',
      invitationAccepted: 'Zaproszenie zostało zaakceptowane',
      invitationDeleted: 'Zaproszenie zostało usunięte',
      invitationRejected: 'Zaproszenie zostało odrzucone',
      popup: {
        acceptInvitationTitle: 'Akceptowanie zaproszenia',
        acceptInvitationInfo: 'Czy chcesz zaakceptować zaproszenie od ',
        deleteInvitationTitle: 'Usuwanie zaproszenia',
        deleteInvitationInfo: 'Czy chcesz usunąć zaproszenie dla ',
        rejectInvitationTitle: 'Odrzucanie zaproszenia',
        rejectInvitationInfo: 'Czy chcesz odrzucić zaproszenie od ',
        confirmButton: 'Tak',
        cancelButton: 'Nie',
        invitationErrorRejected: 'Zaproszenie zostało odrzucone',
        invitationErrorExpired: 'Zaproszenie wygasło',
        invitationErrorAccepted: 'Zaproszenie zostało już zaakceptowane',
      },
    },
  },
  popup: {
    functionNoReady: {
      title: 'Informacja',
      info: 'Ta funkcja nie jest jeszcze dostępna w tej wersji.',
    },
  },
  company: {
    title: 'Firma',
    form: {
      name: 'Nazwa',
      logo: 'Logo',
      save: 'Zapisz',
    },
  },
  ...plLocale,
};
