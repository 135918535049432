import { RootStateInterface } from '@/store/rootState';
import { Module, MutationTree } from 'vuex';
import { PersonalAssistantStateInterface, PersonalAssistantAllocatedBroker } from '../interfaces/PersonalAssistantStateInterface';

export const state: PersonalAssistantStateInterface = {
    personalAssistantId: null,
    currentActingBrokerId: null,
    allocatedBrokers: null,
    currentActingBrokerUser: {
        id: null,
        firstName: null,
        lastName: null,
    },
};

export const mutations: MutationTree<PersonalAssistantStateInterface> = {
    updatePersonalAssistantId(st: PersonalAssistantStateInterface, payload: number | null) {
        st.personalAssistantId = payload;
    },

    updateAllocatedBrokers(st: PersonalAssistantStateInterface, payload: PersonalAssistantAllocatedBroker[] | null) {
        st.allocatedBrokers = payload;
    },

    updateCurrentActingBrokerId(st: PersonalAssistantStateInterface, payload: number | null) {
        st.currentActingBrokerId = payload;
    },

    updateCurrentActingBrokerUser(st: PersonalAssistantStateInterface, payload: any) {
        st.currentActingBrokerUser.id = payload?.id ?? null;
        st.currentActingBrokerUser.firstName = payload?.firstName ?? null;
        st.currentActingBrokerUser.lastName = payload?.lastName ?? null;
    },

    clearState(st) {
        st.personalAssistantId = null;
        st.currentActingBrokerId = null;
        st.allocatedBrokers = null;
        st.currentActingBrokerUser.id = null;
        st.currentActingBrokerUser.firstName = null;
        st.currentActingBrokerUser.lastName = null;
    },
};

const namespaced: boolean = true;

export const personalAssistants: Module<PersonalAssistantStateInterface, RootStateInterface> = {
  namespaced,
  state,
  mutations,
};
